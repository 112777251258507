
.skills {
    margin-top: 13em;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 1.5em;
    font-size: 19px;
}

.skills-title {
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    width: 93%;
}

.skill-list {
    display: flex;
    flex-direction: column;
    width: 90%;
    gap: 0.5em;
}

.skill {
    display: flex;
    justify-content: left;
    gap: 1em;
    width: 100%;

    p {
        font-size: 19px;
    }
}

@media screen and (width < 769px) {
    .skill-list {
        align-items: center;
    }

    .skill {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}