*, ::before, ::after {
  box-sizing: border-box;
}
* {
  padding: 0;
  margin: 0;
}

.app {
  background-color: black;
  color: white;
}

.content {
  font-family: system-ui, sans-serif;
  padding: 11em 2em 1em 2em;
  max-width: 1050px;
}

.section-title {
  margin-bottom: 1.5em;
}

p {
  color: rgba(255, 255, 255, 0.6);
  font-size: 17px;
}

.highlight {
  color: rgb(100, 160, 250);
}

@media screen and (width > 1050px) {
  .app { 
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}