
.nav-bar {
    display: flex;
    overflow: hidden;
    position: fixed;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: black;
    border-bottom: 0.1px solid rgba(255, 255, 255, 0.1);
    gap: 1em;
    padding: 15px 45px;
    z-index: 900;
    max-width: 1050px;

    a {
        text-decoration: none;
        font-size: 17px;
        font-weight: bold;
        color: white;
        padding: 0.4em 1em 0.4em 1em;
        border-radius: 5px;
        transition: all 0.2s ease;
    }

    a:hover {
        background-color: rgba(255, 255, 255, 0.1);
        transition: all 0.2s ease;
    }
}




.modal-background {
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1000;
}

.modal-box {
    background-color: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    border-radius: 8px;
    overflow-y: auto;
}

.resume {
    display: flex;
    flex-direction: column;
    max-width: 850px;
    max-height: 85vh;
    width: 75vw;

    .top-bar {
        display: flex;
        justify-content: right;
        gap: 0.8em;
        border-bottom: 0.5px solid rgba(0,0,0,0.3);

        .resume-button {
            color: black;
            border-radius: 5px;
            text-decoration: none;
            outline: none;
            border: none;
            padding: 0.4em 1.2em 0.4em 1.2em;
            margin-right: 0.5em;
            margin-bottom: 0.6em;
        }
        .close-button {
            background-color: rgba(0, 0, 0, 1);
            color: white;
        }
        .close-button:hover {
            color: rgb(247, 119, 119);
        }
        .download-button:hover {
            color: rgb(47, 106, 233);
        }
    }

    .resume-img {
        overflow-y: scroll;

        .resume-top {
            width: 100%;
        }
        .resume-bottom {
            width: 100%;
        }
    }
}

@media screen and (width < 700px) {
    .resume {
        max-height: 90vh;

        .top-bar {
            gap: 0.2em;

            .resume-button {
                font-size: 15px;
                padding: 0.2em 1em;
                margin-bottom: 1em;
            }
        }
    }
}


.demo-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 700px;
    max-height: 700px;
    width: 70vw;

    .top-bar {
        display: flex;
        justify-content: right;
        border-bottom: 0.5px solid rgba(0,0,0,0.3);
        width: 100%;
        margin-bottom: 1em;

        .close-button {
            background-color: rgba(0, 0, 0, 1);
            color: white;
            border-radius: 5px;
            border: none;
            padding: 0.4em 1.2em 0.4em 1.2em;
            margin-right: 0.8em;
            margin-bottom: 0.8em;
        }
        .close-button:hover {
            color: rgb(247, 119, 119);
        }
    }

    .video {
        width: 90%;
        height: 100%;
        margin-bottom: 1.5em;
    }
}

@media screen and (width < 769px) {
    .nav-bar a {
        font-size: 15px;
        padding: 0.4em 0.6em 0.4em 0.6em;
    }
    .nav-bar {
        gap: 0.5em;
        justify-content: left;
        padding: 14px 15px;
    }

    .hide-small {
        display: none;
    }

    .modal-box {
        padding: 10px;
    }
}

@media screen and (width < 550px) {
    .resume {
        .resume-img {
            overflow: hidden;
        }
    }
}