.project-card {
    display: flex;
    min-height: 300px;
    max-width: 900px;
    padding: 1.5em 1.5em;
    background-color: rgba(255,255,255,0.05);
    border-radius: 10px;
    gap: 4%;
    transition: all .3s ease;
}

.project-card:hover {
    background-color: rgba(255,255,255,0.1);
}

.hide {
    min-height: 0px;
    height: 0px;
    padding: 0px;
    opacity: 0;
    transform: translateY(-100px);
    overflow: hidden;
    transform-origin: top;
    transition: all .3s ease;
}
.show {
    height: auto;
}

.card-left {
    display: flex;
    flex-direction: column;
    width: 55%;
    justify-content: space-between;
}

.card-right {
    width: 40%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgb(190, 190, 190);

    p {
        width: 80%;
        font-size: 14px;
    }

    img {
        max-height: 280px;
        width: 100%;
        margin-bottom: 0.3em;
    }
}

.link-row {
    display: flex;
    gap: 1em;

    .link {
        display: flex;
        background-color: rgba(255, 255, 255, 0.65);
        padding: 0.3em 0.5em;
        text-decoration: none;
        color: black;
        gap: 8px;
        align-items: center;
        width: 6.4em;
        border-radius: 8px;
        transition: all 0.2s ease;
        border: none;
    
        img {
            height: 25px;
            width: 25px;
        }
    }
    .second-link {
        width: 6.8em;
    }
    .link:hover {
        background-color: rgba(255, 255, 255, 1);
    }
}

@media screen and (width < 850px) {
    .card-right {
        display: none;
    }
    .card-left {
        width: 100%;
    }
}