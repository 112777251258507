
.experience-card {
    display: flex;
    justify-content: right;
    width: 100%;
    max-width: 900px;
    position: relative;
}

.left-card {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 15%;
    position: absolute;
    transform: translate(-140%, -10%);

    .experience-circle {
        background-color: rgb(120, 120, 120);
        position: absolute;
        justify-content: center;
        align-items: center;
        width: 65px;
        top: 10%;
        height: 65px;
        border-radius: 50%;

        .triangle-right {
            position: absolute;
            left: 70px;
            top: 10px;
            width: 0;
            height: 0;
            border-top: 25px solid transparent;
            border-bottom: 25px solid transparent;
            border-left: 40px solid rgba(18, 18, 18);
            rotate: 180deg;
        }
    }

    .logo {
        position: absolute;
        top: 5%;
        left: 5%;
        width: 90%;
        height: 90%;
        border-radius: 50%;
        z-index: 500;
    }

    .line {
        background-color: rgb(120, 120, 120);
        position: absolute;
        width: 6px;
        height: 93%;
        transform: translateY(10%);
    }
    .non-last-line {
        height: 120%;
    }
}

.right-card {
    width: 83%;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
    padding: 1.5em 1.5em;
    background-color: rgba(18, 18, 18);
    border-radius: 10px;
    gap: 1em;
}
.right-card:hover {
    background-color: rgba(30, 30, 30);

    .triangle-right {
        border-left: 40px solid rgb(30, 30, 30);
    }
}

.card-content {
    gap: 0.1em;
    display: flex;
    flex-direction: column;
}

h5 {
    margin-bottom: 0.7em;
}

.bullet-point {
    margin: 0;
    margin-left: 2em;
    margin-top: 0.1em;
}

@media screen and (width < 800px) {
    .triangle-right {
        display: none;
    }
}

@media screen and (width < 769px) {
    .left-card {
        display: none;
    }

    .right-card {
        width: 100%;

    }
}