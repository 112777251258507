

.about {
    margin-bottom: 10em;
}

.title {
    color: white;
    font-size: clamp(72px, 8vw, 90px);
    font-family: 'Arial Black', Gadget, sans-serif;
    margin: 0;
    font-weight: bold;
}
.sub-title {
    font-size: clamp(22px, 4vw, 26px);
    margin-bottom: -8px;
}
.intro {
    color: rgb(100, 160, 250);
    font-family: 'Arial Black', Gadget, sans-serif;
}

.about-me {
    margin-top: 12em;
}

.about-me-content {
    align-items: center;
    display: flex;
    gap: 4em;
    width: 100%;
    
    p {
        width: 60%;
    }

    .profile-pic {
        border-radius: 50%;
        height: 220px;
        margin-bottom: 50px;
        width: 220px;
    }
}

.about-buttons {
    display: flex;
    margin-top: 1.5em;
    gap: 0.7em;

    a {
        text-decoration: none;
    }

    .about-button {
        background-color: rgba(255, 255, 255, 0.15);
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        font-weight: bold;
        padding: 0.5em 0.9em;
        transition: all 0.2s ease;
        display: flex;
        gap: 0.7em;
        align-items: center;
    }
    .about-button:hover {
        background-color: rgba(255, 255, 255, 0.25);
        transition: all 0.2s ease;
    }

    .linkedin {
        width: 25px;
        border-radius: 2px;
    }
    
    .github {
        width: 25px;
    }
}

@media screen and (width < 768px) {
    .resume {
        width: 80vw;
    }

    .about-me-content {
        display: flex;       
        flex-direction: column;  
        align-items: center;  
        gap: 1em;
    }

    .title {
        font-size: 60px
    }

    .about-me-content p {
        width: 100%;
    }

    .about-me-content > .profile-pic {
        display: flex;        
        justify-content: center; 
        margin: 10px 0;  
    }
}

@media screen and (width < 550px) {
    .sub-title {
        font-size: 19px;
    }
    .title {
        font-size: 42px;
    }
    .about-buttons {
        .about-button {
            font-size: 13px;
        }

        .linkedin {
            width: 20px;
        }

        .hide-small {
            display: none;
        }
    }
}