
.contact {
    margin-top: 8em;
    background-color: rgb(15, 15, 15);
    width: 100%;
    display: flex;
    gap: 1.5em;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5em;

    h3 {
        width: 80%;
        border-bottom: 0.5px solid rgb(70, 70, 70);
        padding-bottom: 7px;
        text-align: center;
    }
}

.contact-links {
    display: flex;
    gap: 1.3em;
}

.contact-link {
    display: flex;
    align-items: center;
    background-color: rgb(50, 50, 50);
    border-radius: 5px;
    padding: 0.2em 1.4em;
    height: 50px;
    gap: 1em;
    margin-bottom: 3em;
    text-decoration: none;
    color: white;
}

.contact-link:hover {
    background-color: rgb(80, 80, 80);
}

.github-image {
    width: 35px;
}

.mail-image {
    width: 40px;
}

.linkedin-image {
    width: 35px;
    border-radius: 3px;
}

@media screen and (width < 768px) {
    .contact-link {
        height: 45px;
        padding: 0em 0.8em;
        gap: 0.7em;
        font-size: 14.5px;
    }
    .github-image {
        width: 25px;
    }
    .mail-image {
        width: 25px;
    }
    .linkedin-image {
        width: 25px;
    }
}