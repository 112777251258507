
.experience {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10em;
    margin-bottom: 10em;
}

.experience-cards {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5em;
}