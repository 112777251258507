

.projects {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.project-cards {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    margin-bottom: 1em;
}
.dropdown-cards {
    gap: 0em;
    margin-bottom: 0px;
}

.bottom-row {
    max-width: 900px;
    width: 100%;
    display: flex;
    justify-content: right;
    user-select: none;

    .project-expand-button {
        display: flex;
        background-color: rgba(255, 255, 255, 0.05);
        color: rgba(255, 255, 255, 0.8);
        border: none;
        gap: 0.8em;
        align-items: center;
        padding: 0.4em 1.4em;
        border-radius: 6px;
    }
    .project-expand-button:hover {
        background-color: rgba(255, 255, 255, 0.15);
    }
    
    .arrows {
        display: inline-block;
        width: 10px;
        height: 10px;
        border: solid rgba(255, 255, 255, 0.8);
        border-width: 0 2px 2px 0;
        transform: rotate(45deg); /* Default pointing right */
        transition: transform 0.3s ease;
        margin-right: 8px;
        margin-bottom: 3px;
        user-select: none;
    }
    
    .down {
        transform: rotate(225deg);
        margin-top: 10px;
    }
}
