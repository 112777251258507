
.education {
    margin-top: 8em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.education-list {
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 80%;
    margin-top: 1em;
}

.education-card {
    display: flex;
    gap: 1.5em;
    background-color: rgba(15, 15, 15);
    border-radius: 12px;
    padding: 2em 1.5em;

    .logo {
        border-radius: 50%;
        width: 100px;
        height: 100px;
    }

    .education-content {
        display: flex;
        flex-direction: column;
        gap: 0.1em;
    }

    p {
        margin: 0;
    }
}

@media screen and (width < 768px) {
    .education-list {
        width: 94%;
    }

    .education-card {
        gap: 0.9em;

        .logo {
            width: 70px;
            height: 70px;
        }
    }
}